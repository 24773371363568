import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

export default () => (
	<StaticQuery
		query={graphql`
			query helmetQuery {
				site {
					siteMetadata {
						title
						author
						description
						keywords
						imageUrl
					}
				}
			}
		`}
		render={data => (
			<Helmet>
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
				/>
				<meta name="description" content={data.site.siteMetadata.description} />
				<meta name="image" content={data.site.siteMetadata.imageUrl} />
				<meta name="keywords" content={data.site.siteMetadata.keywords} />
				<title>{data.site.siteMetadata.title}</title>
				<html lang="en" />
				{/* Google / Search Engine Meta Tags */}
				<meta itemprop="name" content={data.site.siteMetadata.author} /> />
				<meta name="twitter:card" content={data.site.siteMetadata.description} />
				<meta name="twitter:site" content={data.site.siteMetadata.siteUrl} />
				<meta name="twitter:creator" content={data.site.siteMetadata.author} />
				<meta property="og:url" content={data.site.siteMetadata.siteUrl} />
				<meta property="og:title" content={data.site.siteMetadata.title} />
				<meta property="og:description" content={data.site.siteMetadata.description} />
				<meta property="og:image" content={data.site.siteMetadata.imageUrl} />
				<meta property="og:type" content="website" />
			</Helmet>
		)}
	/>
);
