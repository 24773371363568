import React from 'react'
import { FaFacebookSquare, FaRegEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa'
import { StaticQuery, graphql } from 'gatsby'
import './style.scss'

const Footer = () => (
  <StaticQuery
    query={graphql`
    query SocialQuery {
        site {
          siteMetadata {
          facebook
          email
          map
          phone
        }
      }
    }
`}
    render={data => (
      <footer className='footer center has-background-light'>
        <div className="container is-fluid">
          <div className="columns">
            <div className="column">
              <p className='icon-wrap'>
                1913 East 22nd Street Atlantic, IA 50022
              </p>
              <div className="icon-wrap-two">
                <span className='icon'>
                  <a href={data.site.siteMetadata.map} target="_blank" rel="noopener noreferrer">
                    <FaMapMarkerAlt size='fa-2x' />
                  </a>
                </span>
              </div>
            </div>
            <div className="column">
              <p className='icon-wrap'>
                Every Thursday, Large Group: 6:30pm
            </p>
              <p className='icon-wrap'>
                Small Groups: 7:45pm, Child watch is provided
            </p>
            </div>
            <div className="column">
              <p className='icon-wrap'>
                Celebrate Recovery AG {(new Date().getFullYear())}
              </p>
              <div className="icon-wrap-two">
                <span className='icon'>
                  <a href={data.site.siteMetadata.facebook} target="_blank" rel="noopener noreferrer">
                    <FaFacebookSquare size='fa-2x' />
                  </a>
                </span>
                <span className='icon'>
                  <a href={data.site.siteMetadata.email} target="_blank" rel="noopener noreferrer">
                    <FaRegEnvelope size='fa-2x' />
                  </a>
                </span>
                <span className='icon'>
                  <a href={data.site.siteMetadata.phone} target="_blank" rel="noopener noreferrer">
                    <FaPhone size='fa-2x' />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
