import React from 'react';

import './style.scss';

import masterPieceImg from '../images/CRMasterpieceStill.jpg';

const styles = {
	backgroundImage: `url('${masterPieceImg}')`,
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	position: 'relative',
}

const Header = ({ siteTitle }) => (
	<section 
	className="hero"
	style={styles}
	>
	</section>
);

export default Header;
